/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const HeartFilledIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M22 8.86c0 1.55-.59 3.03-1.65 4.13-2.44 2.53-4.81 5.17-7.34 7.61-.58.55-1.5.53-2.06-.05l-7.3-7.56c-2.21-2.29-2.21-5.98 0-8.26a5.577 5.577 0 018.08 0L12 5l.27-.27C13.34 3.62 14.79 3 16.31 3s2.97.62 4.04 1.73A5.942 5.942 0 0122 8.86z" />
		<path d="M12 21.5c-.51 0-1.03-.2-1.41-.6L3.3 13.34C.91 10.87.91 6.85 3.29 4.38 4.46 3.17 6.02 2.5 7.69 2.5s3.15.63 4.31 1.79c1.15-1.14 2.71-1.79 4.31-1.79s3.25.69 4.4 1.88c1.16 1.2 1.79 2.79 1.79 4.48s-.64 3.28-1.79 4.48c-.8.83-1.6 1.68-2.39 2.52-1.6 1.7-3.25 3.45-4.96 5.1-.38.36-.87.54-1.35.54zm-4.31-18c-1.39 0-2.7.56-3.68 1.58C2 7.17 2 10.56 4.01 12.65l7.29 7.56c.36.38.97.39 1.35.03 1.69-1.62 3.33-3.37 4.92-5.06.8-.85 1.6-1.7 2.4-2.53.98-1.01 1.51-2.35 1.51-3.78s-.54-2.77-1.51-3.78c-.97-1-2.31-1.58-3.68-1.58s-2.71.58-3.68 1.58l-.26.27c-.09.1-.22.15-.36.15-.14 0-.27-.06-.36-.15l-.27-.27a5.07 5.07 0 00-3.68-1.58z" />
	</svg>
))
HeartFilledIconSvg.displayName = 'HeartFilledIconSvg'

const HeartFilledIcon = forwardRef((props, ref) => <Icon component={HeartFilledIconSvg} ref={ref} {...props} />)
HeartFilledIcon.displayName = 'HeartFilledIcon'

HeartFilledIcon.defaultProps = {
	...Icon.defaultProps,
}
HeartFilledIcon.propTypes = {
	...Icon.propTypes,
}

export default HeartFilledIcon
export { HeartFilledIconSvg }
