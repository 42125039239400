import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import Typography from '../../../atoms/typography'
import BHActionWishList from '../../../molecules/action-wish-list'
import BHProductPrice from '../../../molecules/product-price'
import Chip from '../../../molecules/chip'
import AdditionalLabels from '@bluheadless/ui/src/molecules/tile/tile-additional-labels'
import { AdditionalLabelsWrapper } from '@bluheadless/ui/src/molecules/tile/tile.styled'

const shouldForwardProp = (prop) => prop !== 'hasCollection'

const TitleWrapper = styled(Grid, { shouldForwardProp })`
	display: flex;
	align-items: flex-start;
	margin-bottom: ${({ theme }) => theme.spacing(3)};
	${({ theme }) => theme.breakpoints.up('sm')} {
		margin-top: 0px;
	}
	${({ hasCollection }) => (hasCollection ? `flex-wrap: wrap;` : null)}
`

const OutOfStockLabel = styled(Chip)`
	margin-bottom: ${({ theme }) => theme.spacing(2)};
`

const CollectionLabel = styled(Typography)`
	order: 1;
	margin-bottom: ${({ theme }) => theme.spacing(2)};
	${({ theme }) => theme.breakpoints.up('sm')} {
		flex: 1 0 100%;
	}
`

const Title = styled(Typography, { shouldForwardProp })`
	${({ hasCollection }) => (hasCollection ? `flex: 1 0 100%; order: 3;` : null)}
	${({ theme }) => theme.breakpoints.up('sm')} {
		flex: 1 0 calc(100% - 40px);
		${({ hasCollection }) => (hasCollection ? `order: 2;` : null)}
	}
`

const Name = styled(Typography)`
	${({ theme }) => theme.typography.h3}
	font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	font-weight: ${({ theme }) => theme.typography.fontWeightLight};
	line-height: 28px;
	${({ theme }) => theme.breakpoints.up('sm')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(20)};
		line-height: 32px;
	}
`
const Sku = styled(Typography)``

const ActionWishList = styled(BHActionWishList)`
	order: 2;
	margin: ${({ theme }) => theme.spacing(0.5)} 0 ${({ theme }) => theme.spacing(2)} auto;
	${({ theme }) => theme.breakpoints.up('sm')} {
		flex: 1 0 40px;
		margin-bottom: 0;
	}
`

const ProductPrice = styled(BHProductPrice)`
	font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	margin-bottom: ${({ theme }) => theme.spacing(1.5)};
	.old-price,
	.base-price,
	.discount-percentage {
		margin-right: ${({ theme }) => theme.spacing(2)};
	}
`

const VatLabel = styled(Typography)`
	color: ${({ theme }) => theme.palette.grey.vat};
	font-size: ${({ theme }) => theme.typography.pxToRem(10)};
	font-weight: 300;
`

const Divider = styled('span')`
	background-color: var(--color-grey70);
	height: 1px;
	width: 100%;
	display: inline-block;
	margin-top: ${({ theme }) => theme.spacing(3)};
`

const LabelWrapper = styled('div')`
	display: flex;
	gap: var(--spacing-1);
	margin-bottom: var(--spacing-2);
`

const AdditionalLabelsStyled = styled(AdditionalLabels)``

const ProductLabel = styled('div')`
	${AdditionalLabelsWrapper} {
		position: relative;
		top: unset;
	}
`

export {
	AdditionalLabelsStyled,
	CollectionLabel,
	TitleWrapper,
	Title,
	ActionWishList,
	ProductPrice,
	VatLabel,
	Sku,
	Name,
	OutOfStockLabel,
	Divider,
	ProductLabel,
	LabelWrapper,
}
