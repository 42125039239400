import { styled } from '@mui/system'
import Link from '../../../molecules/link'
import Swatch, { TextSwatch } from '../../../molecules/swatch'
import Typography from '../../../atoms/typography'

const shouldForwardProp = (prop) => prop !== 'layout'

const Root = styled('div')`
	width: 100%;
`

const ProductList = styled('div', { shouldForwardProp })`
	margin-top: ${({ theme }) => theme.spacing(1)};
	${({ layout }) =>
		!layout || layout === 'grid'
			? `
				display: flex;
				flex-wrap:wrap;
				gap: 5px;
			`
			: null}
`

const LinkStyled = styled(Link)`
	display: block;
	width: 76px;
	padding: 2px;
	overflow: hidden;
	border: 1px solid transparent;
	&.current {
		border: ${({ theme }) => '1px solid' + theme.palette.primary.main};
	}

	&:hover:not(.current) {
		border: 1px solid ${({ theme }) => theme.palette.grey.light};
	}
`

const SwatchStyled = styled(Swatch)`
	pointer-events: ${({ current }) => (current ? 'none' : 'auto')};
`
const TextSwatchStyled = styled(TextSwatch)`
	pointer-events: ${({ current }) => (current ? 'none' : 'auto')};
`

const Label = styled(Typography)`
	font-family: var(--font-base-medium);
	font-size: var(--size-14);
`

const Value = styled(Typography)`
	text-transform: lowercase;
	display: inline-block;
	margin-left: 4px;
	font-family: var(--font-base-bold);
	font-size: var(--size-14);

	&:first-letter {
		text-transform: uppercase;
	}
`
const AdditionalLabel = styled(Typography)`
	font-size: var(--size-base);
	text-align: left;
	padding: ${({ theme }) => theme.spacing(1)} 4px;
`

export { Root, ProductList, LinkStyled, SwatchStyled, TextSwatchStyled, Label, Value, AdditionalLabel }
