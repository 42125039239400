import { mediaUrl } from '@bluheadless/ui-logic/src/assets'
import { productMainImage } from '@bluheadless/ui-logic/src/utils/product'
import { useCatalogUrl } from '@bluheadless/ui-logic/src/hooks/useCatalogUrl'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useProductContext } from '@bluheadless/ui-logic/src/providers/product'
import { useProducts } from '@bluheadless/ui-logic/src/hooks/product/useProducts'
import { Grid, Box } from '@mui/material'
import {
	Root,
	ProductList,
	LinkStyled,
	SwatchStyled,
	TextSwatchStyled,
	Label,
	Value,
	AdditionalLabel,
} from './related-attributes.styled'
import { Divider } from './sidebar-top.styled'
import SkeletonCard from '../../../molecules/skeleton/card/skeleton-card'
import { cx } from '@emotion/css'
import Link from '../../../molecules/link'
import Image from '../../../particles/image'
import ProductImage from '../../../particles/product-image'
import { useIntl } from 'react-intl'
import Slider, { Slide } from '../../../particles/slider'
import { useThemeProps } from '@mui/system'
import ImageProvider from '@bluheadless/ui-logic/src/providers/image'
import { Fragment } from 'react'

const RELATED_ADDITIONAL_LABEL = 'filter_color'

export const filterProductsByAttribute = (relatedAttributes, parentAttributes) => {
	return relatedAttributes
		.filter(({ attributeCode }) => parentAttributes[attributeCode]?.value?.length > 0)
		.map(({ attributeCode }) => ({
			['attributes.' + attributeCode + '.value']: parentAttributes[attributeCode].value,
		}))
}

const Img = ({
	assets,
	url,
	alt,
	current,
	currentProduct,
	addCurrentLink,
	onHover,
	onClick,
	productsFiltered,
	productId,
	layout,
	imageProviderSize,
	additionalLabel,
}) => {
	const {
		catalog: {
			products: {
				imageType: { catalogBase },
				images: { width: imageWidth, height: imageHeight },
			},
		},
	} = useConfig()

	const productUrl = useCatalogUrl(url)
	const productToShow = !current ? productsFiltered.find(({ id }) => id === productId) : currentProduct
	const WrapperComponent = layout == 'grid' ? ImageProvider : Fragment
	const imageToShow = productMainImage(assets, 'pattern_image')?.image
		? productMainImage(assets, 'pattern_image')?.image
		: productMainImage(assets, catalogBase)?.image
	const altToShow = productMainImage(assets, 'pattern_image')?.alt
		? productMainImage(assets, 'pattern_image')?.alt
		: productMainImage(assets, catalogBase)?.alt

	return (
		// sizes cannot be forwarded to Fragment
		<div>
			<WrapperComponent {...(layout == 'grid' ? { sizes: imageProviderSize } : {})}>
				<LinkStyled
					href={!current || (current && addCurrentLink) ? productUrl : undefined}
					className={current ? 'current' : null}
					onMouseEnter={(e) => {
						if (onHover) {
							onHover(e, { product: productToShow })
						}
					}}
					onClick={(e) => {
						if (onClick) {
							onClick(e, { product: productToShow })
						}
					}}
				>
					{assets[0]?.type === 'video' ? (
						<Image src={assets[0].image} alt={alt} originalWidth={imageWidth} originalHeight={imageHeight} />
					) : (
						<ProductImage
							src={imageToShow}
							alt={alt || altToShow}
							originalWidth={imageWidth}
							originalHeight={imageHeight}
						/>
					)}
				</LinkStyled>
			</WrapperComponent>
			{additionalLabel && <AdditionalLabel>{additionalLabel}</AdditionalLabel>}
		</div>
	)
}

const AttributeType = ({ type, hex, label, src, value, current }) => {
	switch (type) {
		case 'image':
		case 'hex':
			return (
				<SwatchStyled
					label={label}
					hex={hex}
					src={src ? mediaUrl(src) : null}
					current={current}
					value={value ?? null}
					excludeInternalState
				/>
			)
		case 'text':
		default:
			return <TextSwatchStyled value={value ?? null} excludeInternalState current={current} />
	}
}

const Attribute = ({
	type,
	hex,
	label,
	src,
	value,
	url,
	current,
	productId,
	onHover,
	onClick,
	productsFiltered,
	currentProduct,
	addCurrentLink,
	additionalLabel,
}) => {
	const productUrl = useCatalogUrl(url)
	const productToShow = !current ? productsFiltered.find(({ id }) => id === productId) : currentProduct

	return value ? (
		<Link
			underline="none"
			href={!current || (current && addCurrentLink) ? productUrl : undefined}
			onMouseEnter={(e) => {
				if (onHover) {
					onHover(e, { product: productToShow })
				}
			}}
			onClick={(e) => {
				if (onClick) {
					onClick(e, { product: productToShow })
				}
			}}
		>
			<AttributeType current={current} type={type} hex={hex} label={label} src={src} value={value} />
			{additionalLabel && <AdditionalLabel>{additionalLabel}</AdditionalLabel>}
		</Link>
	) : null
}

const RelatedAttribute = ({
	attributeCode,
	viewAttribute,
	view,
	showCurrent,
	showLabel,
	showCurrentLabel,
	showAdditionalLabel,
	onHover,
	onClick,
	currentProduct,
	addCurrentLink,
	layout,
	imageProviderSize,
	isProductPage,
}) => {
	const {
		catalog: {
			products: { relatedAttributes = [] },
		},
	} = useConfig()
	const { formatMessage } = useIntl()
	const productContextValue = useProductContext()
	const [{ assets, product: { attributes: parentAttributes, urlKey, name, id } = {} }] = productContextValue
	const productsToSelect = filterProductsByAttribute(relatedAttributes, parentAttributes)
	const { products, loading: loadingProducts } = useProducts({
		paused: !relatedAttributes?.length > 0 || !productsToSelect.length > 0,
		where: {
			and: [{ visible: 1 }, { id: { neq: id } }, ...productsToSelect],
		},
	})

	const productsFiltered = products?.filter(({ attributes }) => attributes[attributeCode]?.value?.length > 0)

	return loadingProducts && productsToSelect?.length > 0 ? (
		<Box mt={1}>
			<SkeletonCard columns={view === 'image' ? 4 : 12} rows={1} cardRatio={1} textRowsNumber={0} />
		</Box>
	) : productsFiltered?.length > 0 || (showCurrent && parentAttributes[attributeCode]?.value?.length > 0) ? (
		<Grid>
			{showLabel && (
				<Label color="primary.main" variant="body1" gutterBottom>
					{formatMessage({ id: 'related_attribute_' + attributeCode }) + ':'}
					{showCurrentLabel && parentAttributes[attributeCode]?.value?.length > 0 && (
						<Value component={isProductPage ? 'h2' : 'span'} color="primary">
							{' ' + parentAttributes[viewAttribute]?.value}
						</Value>
					)}
				</Label>
			)}
			<ProductList layout={layout}>
				{(!layout || layout === 'grid') && (
					<>
						{showCurrent &&
							parentAttributes[attributeCode]?.value?.length > 0 &&
							(view === 'image' ? (
								<Img
									assets={assets}
									url={urlKey}
									alt={name}
									productId={id}
									onHover={onHover}
									onClick={onClick}
									current
									currentProduct={currentProduct}
									addCurrentLink={addCurrentLink}
									layout={layout}
									imageProviderSize={imageProviderSize}
									additionalLabel={showAdditionalLabel && parentAttributes[RELATED_ADDITIONAL_LABEL]?.value}
								/>
							) : (
								<Attribute
									url={urlKey}
									{...parentAttributes[viewAttribute]}
									onHover={onHover}
									onClick={onClick}
									productsFiltered={productsFiltered}
									current
									currentProduct={currentProduct}
									addCurrentLink={addCurrentLink}
									additionalLabel={showAdditionalLabel && parentAttributes[RELATED_ADDITIONAL_LABEL]?.value}
								/>
							))}
						{productsFiltered?.map(({ assets, urlKey, attributes, name, id }, index) => {
							return view === 'image' ? (
								<Img
									key={index}
									assets={assets}
									productId={id}
									url={urlKey}
									alt={name}
									onHover={onHover}
									onClick={onClick}
									productsFiltered={productsFiltered}
									currentProduct={currentProduct}
									addCurrentLink={addCurrentLink}
									layout={layout}
									imageProviderSize={imageProviderSize}
									additionalLabel={showAdditionalLabel && attributes[RELATED_ADDITIONAL_LABEL]?.value}
								/>
							) : (
								<Attribute
									key={index}
									url={urlKey}
									productId={id}
									{...attributes[viewAttribute]}
									onHover={onHover}
									onClick={onClick}
									productsFiltered={productsFiltered}
									currentProduct={currentProduct}
									addCurrentLink={addCurrentLink}
									additionalLabel={showAdditionalLabel && attributes[RELATED_ADDITIONAL_LABEL]?.value}
								/>
							)
						})}
					</>
				)}
				{layout === 'slider' && (
					<Slider
						breakpoints={{
							320: {
								slidesPerView: 4,
							},
							1440: {
								slidesPerView: 4,
							},
						}}
						navigation={productsFiltered?.length > 4}
						slidesPerView={4}
						spaceBetween={5}
					>
						{showCurrent && parentAttributes[attributeCode]?.value?.length > 0 && (
							<Slide>
								{view === 'image' ? (
									<Img
										assets={assets}
										url={urlKey}
										alt={name}
										productId={id}
										onHover={onHover}
										onClick={onClick}
										current
										currentProduct={currentProduct}
										addCurrentLink={addCurrentLink}
									/>
								) : (
									<Attribute
										url={urlKey}
										{...parentAttributes[viewAttribute]}
										onHover={onHover}
										onClick={onClick}
										productsFiltered={productsFiltered}
										current
										currentProduct={currentProduct}
										addCurrentLink={addCurrentLink}
									/>
								)}
							</Slide>
						)}

						{productsFiltered?.map(({ assets, attributes, id, name, urlKey }, index) => {
							return (
								<Slide key={index}>
									{view === 'image' ? (
										<Img
											assets={assets}
											url={urlKey}
											alt={name}
											productId={id}
											onHover={onHover}
											onClick={onClick}
											currentProduct={currentProduct}
											addCurrentLink={addCurrentLink}
											productsFiltered={productsFiltered}
										/>
									) : (
										<Attribute
											url={urlKey}
											productId={id}
											{...attributes[viewAttribute]}
											onHover={onHover}
											onClick={onClick}
											productsFiltered={productsFiltered}
											currentProduct={currentProduct}
											addCurrentLink={addCurrentLink}
										/>
									)}
								</Slide>
							)
						})}
					</Slider>
				)}
			</ProductList>
			<Divider />
		</Grid>
	) : null
}

const RelatedAttributes = ({ ...inProps }) => {
	const props = useThemeProps({
		name: 'BHRelatedAttributes',
		props: inProps,
	})

	const {
		className,
		onHover,
		onClick,
		showLabel,
		showCurrentLabel,
		addCurrentLink,
		imageSize,
		showAdditionalLabel,
		isProductPage,
	} = props

	const {
		catalog: {
			products: { relatedAttributes = [] },
		},
	} = useConfig()

	// FOR TESTING
	// const relatedAttributes = [
	// 	{
	// 		viewAttribute: 'color',
	// 		attributeCode: 'color',
	// 		layout: 'grid',
	// 		view: 'attribute',
	// 		showCurrent: true,
	// 	},
	// 	{
	// 		viewAttribute: 'size',
	// 		attributeCode: 'size',
	// 		layout: 'grid',
	// 		view: 'attribute',
	// 		showCurrent: true,
	// 	},
	// ]

	const productContextValue = useProductContext()
	const [{ product, product: { attributes: parentAttributes } = {} }] = productContextValue
	const productsToSelect = filterProductsByAttribute(relatedAttributes, parentAttributes)

	return productsToSelect?.length > 0 || relatedAttributes.some(({ showCurrent }) => showCurrent) ? (
		<Root className={cx('RelatedAttributes-root', className)}>
			{relatedAttributes?.map(({ attributeCode, view, viewAttribute, showCurrent, layout }, index) => (
				<RelatedAttribute
					key={index}
					attributeCode={attributeCode}
					view={view}
					viewAttribute={viewAttribute}
					showCurrent={showCurrent}
					showCurrentLabel={showCurrentLabel}
					showLabel={showLabel}
					layout={layout}
					onHover={onHover}
					onClick={onClick}
					currentProduct={product}
					addCurrentLink={addCurrentLink}
					imageProviderSize={imageSize}
					showAdditionalLabel={showAdditionalLabel}
					isProductPage={isProductPage}
				/>
			))}
		</Root>
	) : null
}

export default RelatedAttributes
