import { styled } from '@mui/system'
import Typography from '../../atoms/typography'
import ActionAddToCart from '../action-add-to-cart'

const shouldForwardProp = (prop) => !['afterImage', 'bgColor', 'marginTop', 'position', 'variant'].includes(prop)

const TileWrapper = styled('div')`
	position: relative;
	width: 325px;
`

const InfoWrapper = styled('div', { shouldForwardProp })`
	width: 100%;
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
	/* margin-top: ${({ variant }) => (variant === 'base' ? '10px' : '0px')}; */
	a {
		text-decoration: none;
	}
`
const HoverImageWrapper = styled('div')`
	display: flex;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	opacity: 0;
	transition: opacity 0.2s;
	&.active {
		opacity: 1;
	}
`

const Title1 = styled(Typography)`
	${({ theme }) => theme.typography.body1};
`
const Title2 = styled(Typography)`
	text-transform: uppercase;
`

const NamesWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	width: 100%;
`

const AdditionalLabelsWrapper = styled('div', { shouldForwardProp })`
	position: absolute;
	top: 8px;
	left: ${({ position }) => (position === 'left' ? '8px' : 'auto')};
	right: ${({ position }) => (position === 'right' ? '8px' : 'auto')};
	display: flex;
	flex-direction: column;
	max-width: calc(100% - 16px);
	> div {
		margin-bottom: 5px;
	}

	${({ theme }) => theme.breakpoints.up('md')} {
		top: 16px;
		left: ${({ position }) => (position === 'left' ? '16px' : 'auto')};
		right: ${({ position }) => (position === 'right' ? '16px' : 'auto')};
		max-width: calc(100% - 32px);
	}
`

const PriceContainer = styled('div', { shouldForwardProp })`
	margin-top: ${({ variant }) =>
		variant === 'base' || variant === 'advanced' ? '5px' : variant === 'compact' ? '28px' : '0px'};
`

const ListInfoWrapper = styled('div')`
	width: 100%;
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
	margin-top: 16px;
	${({ theme }) => theme.breakpoints.up('xsm')} {
		margin-top: 0;
	}
	a {
		text-decoration: none;
	}
	${Title1} {
		margin-bottom: 18px;
	}
	${Title2} {
		text-transform: none;
		line-height: 22px;
		margin-bottom: var(--spacing-1);
		${({ theme }) => theme.breakpoints.up('md')} {
			margin-bottom: 18px;
		}
	}
`

const RatingWrapper = styled('div', { shouldForwardProp })`
	margin-bottom: 5px;
	text-align: left;
	${({ variant, theme }) => (['base', 'compact'].includes(variant) ? `padding-top: ${theme.spacing(2)};` : '')}
`

const ColorsWrapper = styled('div', { shouldForwardProp })`
	position: ${({ position }) => (position === 'inner' ? 'absolute' : 'relative')};
	bottom: ${({ position }) => (position === 'inner' ? 'var(--spacing-1)' : 'auto')};
	right: ${({ position }) => (position === 'inner' ? 'var(--spacing-1)' : 'auto')};
	margin-top: ${({ position }) => (position === 'inner' ? '0' : '10px')};
	margin-bottom: ${({ position }) => (position === 'inner' ? '0' : '10px')};

	span {
		color: var(--color-primary);
	}

	${({ theme }) => theme.breakpoints.down('md')} {
		display: none;
	}
`
const ColorsSwatches = styled('div')`
	display: flex;
`

const ActionAddToCartStyled = styled(ActionAddToCart)`
	position: absolute;
`

export {
	TileWrapper,
	InfoWrapper,
	HoverImageWrapper,
	Title1,
	Title2,
	NamesWrapper,
	AdditionalLabelsWrapper,
	PriceContainer,
	ListInfoWrapper,
	RatingWrapper,
	ColorsWrapper,
	ColorsSwatches,
	ActionAddToCartStyled,
}
