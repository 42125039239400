/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const CartIconSvg = forwardRef((props, svgRef) => (
	<svg
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.5 6A1.5 1.5 0 019 4.5h6A1.5 1.5 0 0116.5 6v1.5h-9V6zm-1 1.5V6A2.5 2.5 0 019 3.5h6A2.5 2.5 0 0117.5 6v1.5h3.792a1.1 1.1 0 011.085 1.28l-1.8 10.8a1.1 1.1 0 01-1.085.92H4.508a1.1 1.1 0 01-1.085-.92l-1.8-10.8A1.1 1.1 0 012.708 7.5H6.5zM2.61 8.616a.1.1 0 01.098-.116h18.584a.1.1 0 01.098.116l-1.8 10.8a.1.1 0 01-.098.084H4.508a.1.1 0 01-.098-.084l-1.8-10.8z"
			fill="currentColor"
		/>
	</svg>
))
CartIconSvg.displayName = 'CartIconSvg'

const CartIcon = forwardRef((props, ref) => <Icon component={CartIconSvg} ref={ref} {...props} />)
CartIcon.displayName = 'CartIcon'

CartIcon.defaultProps = {
	...Icon.defaultProps,
}
CartIcon.propTypes = {
	...Icon.propTypes,
}

export default CartIcon
export { CartIconSvg }
